import axios from 'axios';
import { environment } from '~/environment';

export const fetchPartnersByCountry = async (
  country: string,
  locale: string,
) => {
  const hasData = country && locale;

  if (!hasData) {
    throw new Error('errors.partners.not_found');
  }

  const response = await axios.get(
    `${environment.myGrimmeCoreApi}/dealers/v2`,
    {
      params: { language: locale, countryCode: country },
    },
  );

  return response.data;
};

import { useSession } from '@grimme/next-grimme-auth';
import { useProfile } from '../useProfile';

export const useFormattedProfileData = (
  moreText: string,
  greetingText?: string,
) => {
  const { data: profileData } = useProfile();
  const { status } = useSession();

  const isUserLoggedIn = status === 'authenticated';
  const isUserDataLoaded = Boolean(isUserLoggedIn && profileData);

  const company = profileData?.company?.name;
  const fullName =
    profileData && profileData.firstName + ' ' + profileData.lastName;
  const isLoading = status === 'loading' || (isUserLoggedIn && !profileData);
  const userGreeting =
    profileData && `${greetingText} ${profileData.firstName}`;
  const initials =
    profileData &&
    `${profileData.firstName?.charAt?.(0)}${profileData.lastName?.charAt?.(0)}`;

  return {
    company,
    fullName,
    initials,
    isLoading,
    isUserDataLoaded,
    isUserLoggedIn,
    userGreeting,
  };
};

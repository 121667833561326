// TODO: create @grimme/hooks and move to there
import { Geolocation } from '@grimme/types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_GEOLOCATION, fetchGeolocation } from '~/utils';

export const useGeolocation = (defaultLocation = DEFAULT_GEOLOCATION) => {
  const [geolocation, setGeolocation] = useState<Geolocation>(defaultLocation);

  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const geolocation: Geolocation = await fetchGeolocation(language);
        if (isMounted) {
          setGeolocation(geolocation);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (isMounted) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [language]);

  return geolocation;
};

import { ServiceAppointment, ServiceEnquiry } from '@grimme/types';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { environment } from '~/environment';

export class OnlineSalesApi {
  private http: AxiosInstance;
  constructor() {
    this.http = axios.create({ baseURL: environment.onlineSalesApi });
  }

  async postServiceAppointment(
    data: ServiceAppointment,
    locale: string,
  ): Promise<AxiosResponse<unknown>> {
    return this.http.post('/appointments/service', data, {
      params: { lang: locale },
    });
  }

  async postServiceEnquiry(
    data: ServiceEnquiry,
    locale: string,
  ): Promise<AxiosResponse<unknown>> {
    return this.http.post('/enquiry/service', data, {
      params: { lang: locale },
    });
  }
}

import React, { useState } from 'react';
import { fetchPartnersByCountry } from '~/api';
import { Partner } from '~/components/partners/utils';

export const usePartnersByCountry = (countryCode: string, language: string) => {
  const [partners, setPartners] = useState<Partner[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getPartnersList = React.useCallback(async () => {
    setIsLoading(true);
    const partners: Partner[] = await fetchPartnersByCountry(
      countryCode,
      language,
    );
    if (partners) {
      setPartners(partners);
    }
    setIsLoading(false);
  }, [countryCode, language]);

  React.useEffect(() => {
    if (countryCode && language) {
      getPartnersList();
    }
  }, [countryCode, getPartnersList, language]);

  return {
    data: partners,
    isLoading,
  };
};

import { CtasSectionButtonsProps } from '@grimme/components/lib/ctas-section';
import { CtaSectionButtonData } from '~/lib/butter-types/pages/landing-page';
import {
  CountriesListData,
  PartnersCountriesPerContinent,
} from '~/lib/butter-types/pages/partners-page';

export const parseCountriesFromContinents = (
  continents: PartnersCountriesPerContinent,
): CountriesListData[] =>
  Object.keys(continents)
    .reduce((acc, key) => [...acc, ...continents[key]], [])
    .sort((a, b) => a.country_code.localeCompare(b.country_code));

export const parseCtaSectionButtons = (
  buttons: CtaSectionButtonData[],
): CtasSectionButtonsProps[] => {
  return buttons.map((button, index) => ({
    id: String(index),
    label: button.label,
    props: parseButtonProps({ ...button }),
  }));
};

const parseButtonProps = (button) => {
  delete button.label;
  return button;
};

import { Dialog } from '@grimme/components';
import { Whatsapp } from '@grimme/icons';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Box, Checkbox, Link, styled, Typography } from '@mui/material';
import { Trans, useTranslation } from 'next-i18next';
import React from 'react';

const StyledLink = styled(Link)(() => ({
  fontWeight: 600,
  textDecoration: 'none',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  marginTop: theme.spacing(1),
}));

interface WhatsappConsentDialogProps {
  contactNumber: string;
  onCancel: () => void;
  onConfirm: () => void;
  open?: boolean;
}

export const WhatsappConsentDialog = (props: WhatsappConsentDialogProps) => {
  const { contactNumber, onCancel, onConfirm, open } = props;

  const [checked, setChecked] = React.useState(false);
  const insights = useAppInsightsContext();

  const { t } = useTranslation();

  return (
    <Dialog
      confirmButtonProps={{ disabled: !checked }}
      icon={<Whatsapp />}
      labelCancel={t(
        'myGRIMME_products_whatsapp_consent_dialog_cancel',
        'Abbrechen',
      )}
      labelConfirm={t(
        'myGRIMME_products_whatsapp_consent_dialog_confirm',
        'Abschicken',
      )}
      open={open}
      onCancel={onCancel}
      onConfirm={() => {
        localStorage.setItem('allowWhatsapp', Date.now().toFixed(0));
        window.open(`https://wa.me/${contactNumber}`);
        insights?.trackEvent({
          name: 'Contacted Rep',
          properties: {
            method: 'whatsapp',
            rep: contactNumber,
          },
        });
        onConfirm();
      }}
      title={t('myGRIMME_products_whatsapp_headline', 'Whatsapp')}
    >
      <Trans
        i18nKey="myGRIMME_products_whatsapp_consent_description"
        defaults="Bevor es weitergehen kann: Deine Privatsphäre ist uns wichtig. Unsere Datenschutzbedingungen findest Du <a>hier</a>"
        components={{
          a: <StyledLink href="https://my.grimme.com/gdpr" target="_blank" />,
        }}
      />

      <StyledBox>
        <Checkbox
          data-testid="whatsapp-checkbox"
          checked={checked}
          onChange={() => setChecked(!checked)}
        />
        <Typography variant="body1">
          {t(
            'myGRIMME_products_whatsapp_consent_checkbox_text',
            'Ich habe die Datenschutzbedingungen zur Kenntnis genommen.',
          )}
        </Typography>
      </StyledBox>
    </Dialog>
  );
};

import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import { Footer } from '@grimme/components';
import { Box, GlobalStyles, styled } from '@mui/material';
import { ButterPage, GlobalSettings } from '~/lib';
import { NavbarWithContact } from '../navbar-with-contact';
import { PreviewModeButton } from '../preview-mode-button';

const StyledBox = styled(Box)(() => ({
  minHeight: '100vh',
}));

export type LayoutProps = {
  children: React.ReactNode;
  globalSettings: ButterPage<GlobalSettings>;
  preview: boolean;
  withoutContactAssistant?: boolean;
};

export function Layout({
  children,
  globalSettings,
  preview,
  withoutContactAssistant = false,
}: LayoutProps) {
  const footerData = globalSettings.fields.footer;
  const topLinks = globalSettings.fields.top_links;
  const socialLinks = globalSettings.fields.social_links;
  const navigationLinks = globalSettings.fields.navigation_menu;

  const parsedNavigationLinksForFooter = navigationLinks
    .map((l) => l.fields)
    .map((link) => ({
      label: link.label,
      url: link.url,
    }));

  return (
    <>
      {preview && <PreviewModeButton />}
      <NavbarWithContact
        greeting={globalSettings.fields.greeting}
        loggedInMenu={globalSettings.fields.logged_in_menu}
        loggedOutMenu={globalSettings.fields.logged_out_menu}
        more={globalSettings.fields.more}
        navbarData={globalSettings.fields.navbar}
        navigationLinks={navigationLinks}
        socialLinks={socialLinks}
        topLinks={topLinks}
        withoutContactAssistant={withoutContactAssistant}
      />
      <StyledBox>{children}</StyledBox>
      <Footer
        data={{
          title: footerData.title,
          company_information: [
            {
              company_name: footerData.company_name,
              street_address: footerData.street_address,
              postal_code: footerData.postal_code,
              city: footerData.city,
              phone: footerData.phone,
              email: footerData.email,
              website: footerData.website,
            },
          ],
          legal_information_links: footerData.legal_information_links,
        }}
        socialLinks={socialLinks.links.map((socialLink) => ({
          url: socialLink.url,
          icon: {
            prefix: socialLink.icon_type,
            iconName: socialLink.icon,
          } as IconLookup,
        }))}
        sitemap={[
          {
            caption: footerData.sitemap_title,
            links: parsedNavigationLinksForFooter,
          },
          {
            caption: topLinks.title,
            links: topLinks.links,
          },
        ]}
      />
      <GlobalStyles styles={{ html: { scrollBehavior: 'smooth' } }} />
    </>
  );
}

import axios from 'axios';
import * as React from 'react';
import { useState } from 'react';
import { environment } from '~/environment';
import { useGeolocation } from '../useGeolocation';

export type TContactDetails = {
  email: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  phone: string;
  photo: string;
};

//zipCode is a mandatory query in the contact-assistant-details endpoint,
//but an optional argument for the useContactDetails hook. This default
//zip code makes then possible to fetch the data by using an no-existing value.
//TODO: check if it is possible to make zipCode as an optional query in the
//contact-assistant-details endpoint and then remove the defaultZipCode from here.
const defaultZipCode = '0';

export const useContactDetails = (countryCode?: string, zipCode?: string) => {
  const geolocation = useGeolocation(null);

  const [contactDetails, setContactDetails] = useState<TContactDetails>({
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    mobilePhone: undefined,
    phone: undefined,
    photo: undefined,
  });
  const [error, setError] = useState<unknown>(null);
  const [isLoading, setIsLoading] = useState(false);

  const getContactDetails = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const countryCodeQuery = countryCode
        ? countryCode
        : geolocation?.countryCode;
      const zipCodeQuery =
        countryCode && zipCode
          ? zipCode
          : countryCode && countryCode !== geolocation?.countryCode && !zipCode
          ? defaultZipCode
          : geolocation?.zipcode;
      const response = await axios.get(
        `${environment.syncApiUrl}/contact-assistant-details?companyId=100&countryCode=${countryCodeQuery}&languageId=de&zipCode=${zipCodeQuery}`,
      );
      const contactDetailsData = await response.data;
      if (contactDetailsData?.query.salesResponsible) {
        setError(null);
        setContactDetails(contactDetailsData?.query.salesResponsible);
      }
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  }, [countryCode, geolocation, zipCode]);

  React.useEffect(() => {
    if (geolocation || countryCode) {
      getContactDetails();
    }
  }, [countryCode, geolocation, getContactDetails]);

  return {
    data: contactDetails,
    error,
    isLoading,
  };
};
